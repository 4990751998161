window.reportsIndexPageReady = ->

  formatMoney = (value) ->
    if !value
      '0'
    else if value % 1 > 0
      value.toFixed(2)
    else
      value.toString()

  currency = $('[data-series-currency]').data('series-currency')

  # Create theme for page
  Highcharts.indexPageTheme =
    chart:
      type: 'areaspline'
      spacingBottom: 0
      spacingTop: 0
      spacingLeft: 1
      spacingRight: 0
      backgroundColor: null
      borderWidth: 0
      width: null
      height: 100
    colors: [
      '#007FA3'
      '#91D6AC'
      '#FFB81C'
      '#FFA38B'
      '#FF6D6A'
      '#F9B5C4'
      '#99D6EA'
      '#F8E08E'
      '#EF6079'
    ]
    lang: noData: ''
    legend: enabled: false
    plotOptions:
      spline:
        threshold: 0.1
    title: text: ''
    tooltip:
      backgroundColor: '#ffffff'
      borderRadius: 4
      borderWidth: 0
      formatter: ->
        '<strong>' + @point.options.name + '</strong>: ' + @y
      shadow: false
      style:
        'fontSize': '14px'
        'padding': '10px'
    xAxis:
      gridLineWidth: 0
      labels: enabled: false
    yAxis:
      gridLineWidth: 0
      labels: enabled: false
      min: 0
      minRange: 0.1
      title: enabled: false

  # Apply the custom theme to page
  Highcharts.setOptions Highcharts.indexPageTheme

  charts = [
    'appointments-chart',
    'daily-payments-chart',
    'marketing-chart',
    'patients-chart',
    'practitioner-chart',
    'revenue-chart'
  ]

  chartOptions =
    'appointments-chart':
      series: [{
        color: '#99D6EA'
      }]
    'patients-chart':
      series: [{
        color: '#FFB81C'
      }]
    'revenue-chart':
      tooltip: formatter: ->
        '<strong>' + @point.options.name + '</strong> - ' + currency + formatMoney(@y)
      series: [{
        color: '#91D6AC'
      }]
    'daily-payments-chart':
      tooltip: formatter: ->
        '<strong>' + @point.options.name + '</strong> - ' + currency + formatMoney(@y)
      series: [{
        color: '#FFA38B'
      }]
    'practitioner-chart':
      chart:
        type: 'spline'
      tooltip: formatter: ->
        '<strong>' + @series.name + '</strong> : ' + currency +
          formatMoney(@y) + ' in ' + @point.options.name
    'marketing-chart':
      series: [{
        color: '#F9B5C4'
      }]

  for chartName in charts when $("[data-js-#{chartName}]").length
    $.getJSON '/reports/charts', chart_name: chartName, (data) ->
      for chartName, chartSeries of data
        chart     = $("[data-js-#{chartName}]")

        options = chartOptions[chartName]

        if chartName is 'practitioner-chart'
          options.chart.renderTo = chart[0]
          options.series = chartSeries
        else
          options.chart = { renderTo: chart[0] }
          options.series[0].data = chartSeries

        Highcharts.chart options

        if chartSeries.length == 0
          chart.html('<p class="absolute-center text-center"><span class="block">No data yet!</span></p>')
          chart.next($('[data-js-chart-caption]')).hide()

        chart.prev($('[data-js-chart-loader]')).hide()

  return
