import autosize from 'autosize'
import { asCurrency, convertNegativeZeroToZero, humanize, isNumber, thru, toCents, toDecimal, toFixed, toNumber } from 'modules/util'
import * as R from 'ramda'
import Invoice from "resources/invoice";
import InvoiceItem from "resources/invoice-item";

const CURRENCY_DATA = Object.freeze({
  delimiter: $("[data-js-currency-delimiter]").val(),
  format: $("[data-js-currency-format]").val(),
  precision: toNumber($("[data-js-currency-precision]").val()),
  separator: $("[data-js-currency-separator]").val(),
  subunitsPerUnit: toNumber($("[data-js-currency-subunits-per-unit]").val()),
  unit: $("[data-js-currency-unit]").val()
});

window.InvoiceFormPageReady = function InvoiceFormPageReady() {
  var $pageContainer = $("[data-js-page-container]");

  $("[data-js-invoice-patient-select]").select2({
    minimumInputLength: 1,
    initSelection: function(element, callback) {
      var data = { id: element.val(), text: element.data("defaultText") };
      callback(data);
    },
    formatResult: patientFormatResult,
    ajax: {
      url: $("[data-js-invoice-patient-select]").data("getPatients"),
      dataType: "json",
      quietMillis: defaultAutocompleteDelay,
      data: function(term, page) {
        return {
          term: term
        };
      },
      results: function(data, page) {
        return { results: data };
      }
    }
  });

  $('[data-js-invoice-form]').submit(function (e) {
    removeInvoiceItemsRequiredErrorMessage();
    if (invoiceItemsCount() < 1) {
      e.preventDefault();
      addInvoiceItemsRequiredErrorMessage();
      setTimeout(stopRgButtonLoads);
    } else if (hasMissingSourceItem()) {
      e.preventDefault();
      addSourceItemRequiredErrorMessage();
      setTimeout(stopRgButtonLoads);
    }
  });

  $('[data-js-create-credit-and-refund]').on('click', function (e) {
    $('[data-js-invoice-form]').append('<input type="hidden" name="refund" value="true" />')
  })

  if (isPastBreakpoint("large")) {
    var patientInput = $("[data-js-invoice-patient-select]");
    if (
      patientInput.val() === "" &&
      !patientInput.hasClass("field_with_errors")
    ) {
      setTimeout(function() {
        $("[data-js-invoice-patient-select]").select2("open");
      }, 100);
    } else {
      setTimeout(function() {
        $("[data-js-invoice-submit-button]").focus();
      }, 100);
    }
  }

  /* item unit_price changed */
  $pageContainer.on(
    "change",
    "[id^='invoice_invoice_items_attributes_'][id$='_unit_price']",
    function() {
      updateInvoice();
    }
  );

  /* item quantity changed */
  $pageContainer.on(
    "change",
    "[id^='invoice_invoice_items_attributes_'][id$='_quantity']",
    function() {
      updateInvoice();
    }
  );

  /* item tax amount changed */
  $pageContainer.on("change", "[data-js-tax-rate]", function() {
    updateInvoice();
  });

  /* item discount percentage changed */
  $pageContainer.on(
    "change",
    "[id^='invoice_invoice_items_attributes'][id$='_discount_percentage']",
    function() {
      updateItemDiscountedAmount(this);
      updateInvoice();
    }
  );

  $pageContainer.on(
    "change",
    "[id^='invoice_invoice_items_attributes'][id$='_discounted_amount']",
    function() {
      updateItemDiscountPercentage(this);
      updateInvoice();
    }
  );

  $pageContainer.on(
    "change",
    "[id^='invoice_invoice_items_attributes'][id$='_is_monetary_discount']",
    function() {
      swapDiscountFields(this);
      updateInvoice();
    }
  );

  /* source_item changed */
  $pageContainer.on(
    "change",
    "[id^='invoice_invoice_items_attributes'][id$='_source_item_id']",
    function() {
      source_itemChanged(this);
    }
  );

  $("[data-js-invoice-practitioner-select]").select2({
    minimumResultsForSearch: 10
  });

  prepareAppointmentsDropdown($("[data-js-invoice-appointment-select]"));
  showSelectFor("BillableItem");
  showSelectFor("Product");
  prepareDiscountFields();

  if (
    $("[data-js-invoice-patient-select]").val() != "" &&
    $("[data-js-invoice-appointment-select]").val() == ""
  ) {
    reloadAppointments(
      $("[data-js-invoice-patient-select]").val(),
      $("[data-js-invoice-practitioner-select]").val()
    );
  }

  function removePatientSelect2ErrorMessage() {
    var $patientContainer = $("[data-js-invoice-patient-container]");
    if ($patientContainer.hasClass("field_with_errors")) {
      $patientContainer.removeClass("field_with_errors");
      var $patientErrors = $patientContainer.find(".field_with_errors");
      $patientErrors.removeClass("field_with_errors");
      $patientContainer.find(".message").remove();
    }
  }

  function addInvoiceItemsRequiredErrorMessage() {
    var $invoiceItemsErrorContainer = $('[data-js-invoice-items-error]')
    if (!$invoiceItemsErrorContainer.hasClass('field_with_errors')) {
      $invoiceItemsErrorContainer.addClass("field_with_errors");
      $invoiceItemsErrorContainer.append(
        $("<div class='message margin-2' data-js-invoice-items-error-message>You must have at least 1 invoice item.</div>")
      );
    }
  }

  function removeInvoiceItemsRequiredErrorMessage() {
    var $invoiceItemsErrorContainer = $('[data-js-invoice-items-error]')
    if ($invoiceItemsErrorContainer.hasClass('field_with_errors')) {
      $invoiceItemsErrorContainer.removeClass("field_with_errors");
      $invoiceItemsErrorContainer.find("[data-js-invoice-items-error-message]").remove();
    }
  }

  function addSourceItemRequiredErrorMessage() {
    $("[id^='invoice_invoice_items_attributes'][id$='_source_item_id']").each(
      function() {
        if ($(this).val() || $(this).parent().is(':hidden')) return;

        removeSourceItemRequiredErrorMessage(this);

        var sourceItemType = humanize($(this).attr('source_item_type'));
        var target = $(this).parent();
        target.addClass('field_with_errors');
        target.append(
          $("<div class='message' data-js-invoice-items-error-message>A " + sourceItemType + " is required.</div>")
        );
      }
    );
  }

  $("[data-js-invoice-patient-select]").on("change", function() {
    var patientId = $(this).val();
    var patientName = document.querySelector("a.select2-choice").innerText;
    angularCompile(
      $("recent-invoices-pullout")
        .attr("patient-id", patientId)
        .attr("patient-name", patientName)
    );

    $.ajax({
      url: $(this).data("path"),
      data: { patient_id: patientId },
      async: true,
      dataType: "json",
      success: function(data) {
        autosize.update(
          $("[data-js-invoice-patient-extra-information]").val(
            data.invoice_extra_information
          )
        );
        autosize.update($("[data-js-invoice-invoice-to]").val(data.invoice_to));
        removePatientSelect2ErrorMessage();
      }
    });

    refresh_all_billable_items();
    reloadAppointments(
      patientId,
      $("[data-js-invoice-practitioner-select]").val()
    );
    var match = window.location.href.match(/\/invoices\/(\d+)\//);
    if (match) {
      var invoiceId = match[1];
    } else {
      var invoiceId = null;
    }
    reloadNotifications(patientId, invoiceId);
    checkMedipassAvailability(patientId);
  });

  $("[data-js-invoice-practitioner-select]").on("change", function() {
    reloadAppointments(
      $("[data-js-invoice-patient-select]").val(),
      $(this).val()
    );
  });

  $("[data-js-invoice-appointment-select]").on("change", function() {
    angularCompile(
      $("invoice-case").attr("attendee-id", $(this).val())
    );
    angularCompile(
      $("invoice-case-warning")
        .attr("attendee-id", $(this).val())
        .attr("net-amount", $("[data-js-invoice-net-amount]").val())
    );
  });

  $("[data-js-invoice-net-amount]").on("change", function() {
    angularCompile(
      $("invoice-case-warning")
        .attr("attendee-id", $("[data-js-invoice-appointment-select]").val())
        .attr("net-amount", $(this).val())
    );
  });
}

window.patientFormatResult = function patientFormatResult(patient) {
  return (
    sanitizeHtml(patient.text) +
    '<small title="Date of Birth" class="small color-font-light">' +
    patient.dob +
    "</small>"
  );
}

window.prepareDiscountFields = function prepareDiscountFields() {
  $.each(
    $("[id^='invoice_invoice_items_attributes'][id$='_is_monetary_discount']"),
    function(index, element) {
      swapDiscountFields(element);
    }
  );
}

window.prepareAppointmentsDropdown = function prepareAppointmentsDropdown(dropdown) {
  dropdown.select2({
    minimumResultsForSearch: 10
  });

  if (dropdown.find("option").length <= 1) {
    dropdown.select2("enable", false);
  } else {
    dropdown.select2("enable", true);
  }
}

window.reloadNotifications = function reloadNotifications(patientId, invoiceId) {
  $.ajax({
    url: $("[data-js-notifications]").data("js-notifications"),
    data: { invoice_id: invoiceId, patient_id: patientId },
    dataType: "html",
    success: function(data) {
      $("[data-js-notifications]").html(angularCompile(data));
    }
  });
}

window.checkMedipassAvailability = function checkMedipassAvailability(patientId) {
  $.ajax({
    url: $("[data-js-medipass-availability]").data("js-medipass-availability"),
    data: { id: patientId },
    dataType: "html",
    success: function(data) {
      $("[data-js-medipass-availability]").html(data);
    },
    error: function() {
      $("[data-js-medipass-availability]").html("");
    }
  });
}

window.reloadAppointments = function reloadAppointments(patient_id, practitioner_id) {
  var dropdown = $("[data-js-invoice-appointment-select]");

  $.ajax({
    url: $("[data-js-invoice-appointment-select]").data("path"),
    data: { patient_id: patient_id, practitioner_id: practitioner_id },
    async: true,
    dataType: "json",
    success: function(data) {
      dropdown.find("option").remove();
      $.each(data, function(index, value) {
        dropdown.append(
          $("<option></option>")
            .val(value.id)
            .text(value.text)
        );
      });
      dropdown.select2("destroy");
      prepareAppointmentsDropdown(dropdown);
    }
  });
}

window.showSelectFor = function showSelectFor(itemType, focus) {
  var itemLabel = itemType === "Product" ? "product" : "billable item";

  $("input[source_item_type$='" + itemType + "']").each(function() {
    if (!isNaN($(this).select2("val"))) {
      return;
    }

    if ($(this).data("items")) {
      var items = $(this).data("items");

      function format(item) {
        return _.escape(item.to_label);
      }

      $(this).select2({
        placeholder: "Search for a " + itemLabel + "… ",
        initSelection: function(element, callback) {
          var data = {
            id: element.val(),
            to_label: element.data("defaultText")
          };
          callback(data);
        },
        data: { results: items, text: "to_label" },
        formatSelection: format,
        formatResult: format,
        matcher: function(term, text, opt) {
          return (
            text.toUpperCase().indexOf(term.toUpperCase()) >= 0 ||
            (opt["notes"] || "").toUpperCase().indexOf(term.toUpperCase()) >= 0 ||
            (opt["serial_number"] || "").toUpperCase().indexOf(term.toUpperCase()) >= 0
          );
        }
      });
    } else {
      $(this).select2({
        minimumInputLength: 1,
        placeholder: "Search for a " + itemLabel + "… ",
        initSelection: function(element, callback) {
          var data = { id: element.val(), text: element.data("defaultText") };
          callback(data);
        },
        ajax: {
          url: $("[source_item_type$='" + itemType + "']").data("getItems"),
          dataType: "json",
          quietMillis: defaultAutocompleteDelay,
          data: function(term, page) {
            return {
              term: term
            };
          },
          results: function(data, page) {
            return { results: data };
          }
        }
      });
    }

    if (focus) {
      $(this).select2('open');
    }
  });
}

/* Invoices/Edit */
window.get_inline_unit_price = function get_inline_unit_price(sender) {
  return $(sender)
    .closest("[data-js-fields-group]")
    .find("[id^='invoice_invoice_items_attributes_'][id$='_unit_price']")
    .first();
}

window.get_inline_quantity = function get_inline_quantity(sender) {
  return $(sender)
    .closest("[data-js-fields-group]")
    .find("[id^='invoice_invoice_items_attributes_'][id$='_quantity']")
    .first();
}

window.get_inline_tax_name = function get_inline_tax_name(sender) {
  return $(sender)
    .closest("[data-js-fields-group]")
    .find(".tax_name")
    .first();
}

window.get_inline_tax_rate = function get_inline_tax_rate(sender) {
  return $(sender)
    .closest("[data-js-fields-group]")
    .find("[data-js-tax-rate]")
    .first();
}

window.get_inline_tax_id = function get_inline_tax_id(sender) {
  return $(sender)
    .closest("[data-js-fields-group]")
    .find("[id^='invoice_invoice_items_attributes_'][id$='_tax_id']")
    .first();
}

window.get_inline_discount_percentage = function get_inline_discount_percentage(sender) {
  return $(sender)
    .closest("[data-js-fields-group]")
    .find(
      "[id^='invoice_invoice_items_attributes_'][id$='_discount_percentage']"
    )
    .first();
}

window.get_inline_discounted_amount = function get_inline_discounted_amount(sender) {
  return $(sender)
    .closest("[data-js-fields-group]")
    .find("[id^='invoice_invoice_items_attributes_'][id$='_discounted_amount']")
    .first();
}

window.get_inline_discount_type = function get_inline_discount_type(sender) {
  var discount_checkbox = $(sender)
    .closest("[data-js-fields-group]")
    .find(
      "[id^='invoice_invoice_items_attributes_'][id$='_is_monetary_discount']"
    )
    .first();

  if (discount_checkbox.val() === "true") {
    return "fixed";
  } else {
    return "percentage";
  }
}

window.get_inline_net_price = function get_inline_net_price(sender) {
  return $(sender)
    .closest("[data-js-fields-group]")
    .find("[data-js-invoice-item-net-price-field]")
    .first();
}

window.get_inline_total_inc_tax = function get_inline_total_inc_tax(sender) {
  return $(sender)
    .closest("[data-js-fields-group]")
    .find("[data-js-invoice-item-total-inc-tax-field]")
    .first();
}

window.get_inline_hidden_concession_type_id = function get_inline_hidden_concession_type_id(sender) {
  return $(sender)
    .closest("[data-js-fields-group]")
    .find("[id^='invoice_invoice_items_attributes_'][id$='concession_type_id']")
    .first();
}

window.get_inline_hidden_concession_type_name = function get_inline_hidden_concession_type_name(sender) {
  return $(sender)
    .closest("[data-js-fields-group]")
    .find(
      "[id^='invoice_invoice_items_attributes_'][id$='concession_type_name']"
    )
    .first();
}

window.get_inline_concession_label = function get_inline_concession_label(sender) {
  return $(sender)
    .closest("[data-js-fields-group]")
    .find("div.concession_label")
    .first();
}

window.get_inline_concession_label_name = function get_inline_concession_label_name(sender) {
  return $(sender)
    .closest("[data-js-fields-group]")
    .find("span.concession_label_name")
    .first();
}

window.get_inline_discount_value = function get_inline_discount_value(discount_type, sender) {
  if (discount_type === "percentage") {
    return get_inline_discount_percentage(sender).val();
  } else {
    return get_inline_discounted_amount(sender).val();
  }
}

window.getInvoiceItemData = function getInvoiceItemData(sender, subunitsPerUnit) {
  var calculationMethod = getInvoiceCalculationMethod();
  var quantity = toNumber(get_inline_quantity(sender).val());
  var taxRate = toNumber(get_inline_tax_rate(sender).val());
  var discountType = get_inline_discount_type(sender);
  var discountValue = toNumber(get_inline_discount_value(discountType, sender));
  var unitPrice = toNumber($(sender).val());

  if (calculationMethod !== Invoice.CALCULATION_METHODS.legacy) {
    unitPrice = toCents(subunitsPerUnit, unitPrice);
    if (discountType === "fixed") {
      discountValue = toCents(subunitsPerUnit, discountValue);
    }
  }

  return {
    unitPrice,
    quantity,
    taxRate,
    discountType,
    discountValue
  };
}

window.getInvoiceCalculationMethod = function getInvoiceCalculationMethod() {
  return toNumber($("[data-js-invoice-calculation-method]").val());
}

window.centAmountAsCurrency = function centAmountAsCurrency(amount) {
  if (!isNumber(amount)) {
    return "Error";
  }

  return asCurrency(CURRENCY_DATA)(amount);
}

window.swapDiscountFields = function swapDiscountFields(sender) {
  var discountPercentageField = get_inline_discount_percentage(sender);
  var discountedAmountField = get_inline_discounted_amount(sender);
  var activeDiscountType = get_inline_discount_type(sender);

  if (activeDiscountType === "percentage") {
    discountedAmountField.hide();
    discountPercentageField.show();
  } else {
    discountPercentageField.hide();
    discountedAmountField.show();
  }
}

window.updateItemDiscountedAmount = function updateItemDiscountedAmount(sender) {
  var discountPercentageField = get_inline_discount_percentage(sender);
  var discountedAmountField = get_inline_discounted_amount(sender);

  discountedAmountField.val(discountPercentageField.val());
}

window.updateItemDiscountPercentage = function updateItemDiscountPercentage(sender) {
  var discountPercentageField = get_inline_discount_percentage(sender);
  var discountedAmountField = get_inline_discounted_amount(sender);

  discountPercentageField.val(discountedAmountField.val());
}

window.writeInvoiceItem = function writeInvoiceItem({
  sender,
  invoiceItem,
  validInvoiceItem,
  calculationMethod
})
{
  var totalIncTaxField = get_inline_total_inc_tax(sender);
  var netPriceField = get_inline_net_price(sender);
  var totalIncTaxText = $(sender)
    .closest("[data-js-fields-group]")
    .find("[data-js-invoice-item-total-inc-tax]");
  var netPriceText = $(sender)
    .closest("[data-js-fields-group]")
    .find("[data-js-invoice-item-net-price]");
  var legacyPrecision = 2;

  if (calculationMethod === Invoice.CALCULATION_METHODS.legacy) {
    assignNumericValue(totalIncTaxField, invoiceItem.totalIncTax, validInvoiceItem, legacyPrecision);
    totalIncTaxText.text(totalIncTaxField.val());
    invoice_item_changed_animation(totalIncTaxText);
  } else if (calculationMethod === Invoice.CALCULATION_METHODS.taxExclusive) {
    assignNumericValue(
      netPriceField,
      validInvoiceItem ? toDecimal(CURRENCY_DATA.subunitsPerUnit, invoiceItem.netPrice) : undefined,
      validInvoiceItem,
      CURRENCY_DATA.precision
    );
    netPriceText.text(centAmountAsCurrency(invoiceItem.netPrice));
    invoice_item_changed_animation(netPriceText);
  } else {
    assignNumericValue(
      totalIncTaxField,
      validInvoiceItem ? toDecimal(CURRENCY_DATA.subunitsPerUnit, invoiceItem.totalIncTax) : undefined,
      validInvoiceItem,
      CURRENCY_DATA.precision
    );
    totalIncTaxText.text(centAmountAsCurrency(invoiceItem.totalIncTax));
    invoice_item_changed_animation(totalIncTaxText);
  }
}

window.writeInvoice = function writeInvoice({
  invoice,
  validInvoice,
  calculationMethod
})
{
  var totalAmountField = $("[data-js-invoice-total-amount]");
  var taxAmountField = $("[data-js-invoice-tax-amount]");
  var discountedAmountField = $("[data-js-invoice-discounted-amount]");
  var netAmountField = $("[data-js-invoice-net-amount]");
  var legacyPrecision = 2;

  if (calculationMethod === Invoice.CALCULATION_METHODS.legacy) {
    assignNumericValue(
      discountedAmountField,
      thru(invoice.discountedAmount, R.negate, convertNegativeZeroToZero),
      validInvoice,
      legacyPrecision
    );
    $("[data-js-invoice-discount]").text(discountedAmountField.val());

    assignNumericValue(totalAmountField, invoice.totalAmount, validInvoice, legacyPrecision);
    $("[data-js-invoice-subtotal]").text(totalAmountField.val());

    assignNumericValue(taxAmountField, invoice.taxAmount, validInvoice, legacyPrecision);
    $("[data-js-invoice-tax]").text(taxAmountField.val());

    assignNumericValue(netAmountField, invoice.netAmount, validInvoice, legacyPrecision);
    $("[data-js-invoice-total]").text(netAmountField.val());
  } else {
    assignNumericValue(
      discountedAmountField,
      validInvoice
        ? thru(
            invoice.discountedAmount,
            toDecimal(CURRENCY_DATA.subunitsPerUnit),
            R.negate,
            convertNegativeZeroToZero
          )
        : undefined,
      validInvoice,
      CURRENCY_DATA.precision
    );
    $("[data-js-invoice-discount]").text(
      centAmountAsCurrency(thru(invoice.discountedAmount, R.negate, convertNegativeZeroToZero))
    );

    if (calculationMethod === Invoice.CALCULATION_METHODS.taxExclusive) {
      assignNumericValue(
        totalAmountField,
        validInvoice ? toDecimal(CURRENCY_DATA.subunitsPerUnit, invoice.totalAmount) : undefined,
        validInvoice,
        CURRENCY_DATA.precision
      );
      $("[data-js-invoice-subtotal]").text(centAmountAsCurrency(invoice.totalAmount));
    } else {
      assignNumericValue(
        totalAmountField,
        validInvoice ? toDecimal(CURRENCY_DATA.subunitsPerUnit, invoice.netAmount) : undefined,
        validInvoice,
        CURRENCY_DATA.precision
      );
      $("[data-js-invoice-subtotal]").text(centAmountAsCurrency(invoice.netAmount));
    }

    assignNumericValue(
      taxAmountField,
      validInvoice ? toDecimal(CURRENCY_DATA.subunitsPerUnit, invoice.taxAmount) : undefined,
      validInvoice,
      CURRENCY_DATA.precision
    );
    $("[data-js-invoice-tax]").text(centAmountAsCurrency(invoice.taxAmount));

    assignNumericValue(
      netAmountField,
      validInvoice ? toDecimal(CURRENCY_DATA.subunitsPerUnit, invoice.netAmount) : undefined,
      validInvoice,
      CURRENCY_DATA.precision
    );
    $("[data-js-invoice-total]").text(centAmountAsCurrency(invoice.netAmount));
  }

  invoice_item_changed_animation($("[data-js-invoice-discount]"));
  invoice_item_changed_animation($("[data-js-invoice-subtotal]"));
  invoice_item_changed_animation($("[data-js-invoice-tax]"));
  invoice_item_changed_animation($("[data-js-invoice-total]"));
}

window.updateInvoice = function updateInvoice() {
  var invoiceItems = [];
  var invoice = {};
  var validInvoice = true;
  var calculationMethod = getInvoiceCalculationMethod();

  $("[id^='invoice_invoice_items_attributes_'][id$='_unit_price']").each(
    function() {
      var itemData = getInvoiceItemData(this, CURRENCY_DATA.subunitsPerUnit);
      var invoiceItem = {};
      var validInvoiceItem = true;

      try {
        invoiceItem = InvoiceItem.calculateFields({ calculationMethod, ...itemData });
        invoiceItems.push(invoiceItem);
      } catch(e) {
        validInvoiceItem = false;
        validInvoice = false;
      }

      writeInvoiceItem({
        sender: this,
        invoiceItem,
        validInvoiceItem,
        calculationMethod,
      });
    }
  );

  if (validInvoice) {
    invoice = Invoice.calculateFields({ calculationMethod, invoiceItems });
  }

  writeInvoice({
    invoice,
    validInvoice,
    calculationMethod
  });
}

window.assignNumericValue = function assignNumericValue(field, value, validNumber, precision) {
  if (validNumber) {
    field.attr("full_value", value);
    field.val(toFixed(precision, value));
  } else {
    field.attr("full_value", "Error");
    field.val("Error");
  }

  field.trigger("change");
  invoice_item_changed_animation(field);
}

window.refresh_all_billable_items = function refresh_all_billable_items() {
  $("[id^='invoice_invoice_items_attributes'][id$='_source_item_id']").each(
    function() {
      source_itemChanged(this, true);
    }
  );
}

window.source_itemChanged = function source_itemChanged(sender, keepQuantity) {
  var inline_unit_price = get_inline_unit_price(sender);
  var inline_quantity = get_inline_quantity(sender);
  var inline_tax_name = get_inline_tax_name(sender);
  var inline_tax_rate = get_inline_tax_rate(sender);
  var inline_tax_id = get_inline_tax_id(sender);
  var inline_concession_label = get_inline_concession_label(sender);
  var inline_concession_label_name = get_inline_concession_label_name(sender);
  var inline_hidden_concession_type_id = get_inline_hidden_concession_type_id(
    sender
  );
  var inline_hidden_concession_type_name = get_inline_hidden_concession_type_name(
    sender
  );

  $(sender)
    .closest("[data-js-fields-group]")
    .find("[data-js-invoice-product-deleted]")
    .hide();
  removeSourceItemRequiredErrorMessage(sender);

  if ($(sender).val() == "") {
    inline_unit_price.val("");
    inline_unit_price.trigger("change");
    invoice_item_changed_animation(inline_unit_price);

    inline_quantity.val("");
    inline_quantity.trigger("change");
    invoice_item_changed_animation(inline_quantity);

    inline_tax_name.text("N/A");
    inline_tax_rate.val("");
    inline_tax_rate.trigger("change");
    inline_tax_id.val("");
    invoice_item_changed_animation(inline_tax_name);
  } else {
    $.ajax({
      url: $(sender).attr("ajax_path"),
      data: {
        id: $(sender).val(),
        patient_id: $("[data-js-invoice-patient-select]").val()
      },
      async: true,
      dataType: "json",
      success: function(data) {
        if ($(sender).attr("source_item_type") === "Product") {
          inline_unit_price.val(formatMoneyNumber(Number(data.product.product.price), 3));

          // show stock warning message if stock is 0
          if (data.product.product.stock_level === 0) {
            $(sender)
              .closest("[data-js-fields-group]")
              .find("[data-js-invoice-product-stock-warning]")
              .show();
          } else {
            $(sender)
              .closest("[data-js-fields-group]")
              .find("[data-js-invoice-product-stock-warning]")
              .hide();
          }
        } else {
          inline_unit_price.val(formatMoneyNumber(Number(data.unit_price_for_patient), 3));
        }

        inline_unit_price.trigger("change");
        invoice_item_changed_animation(inline_unit_price);

        if (!keepQuantity) {
          inline_quantity.val("1");
          inline_quantity.trigger("change");
          invoice_item_changed_animation(inline_quantity);
        }

        if (data.tax != null) {
          inline_tax_name.text(data.tax.tax.name);
          inline_tax_rate.val(data.tax.tax.rate);
          inline_tax_id.val(data.tax.tax.id);
        } else {
          inline_tax_name.text("N/A");
          inline_tax_rate.val("");
          inline_tax_id.val("");
        }
        inline_tax_rate.trigger("change");
        invoice_item_changed_animation(inline_tax_name);

        inline_hidden_concession_type_id.val(data.concession_type_id);
        inline_hidden_concession_type_name.val(data.concession_type_name);

        if (data.concession_type_id != null) inline_concession_label.show();
        else inline_concession_label.hide();

        inline_concession_label_name.text(data.concession_type_name);
      }
    });
  }
}

window.invoiceItemsCount = function invoiceItemsCount() {
  return $("[data-js-invoice-items]").children(":visible").length;
}

window.hasMissingSourceItem = function hasMissingSourceItem() {
  var hasMissing = false;

  $("[id^='invoice_invoice_items_attributes'][id$='_source_item_id']").each(
    function() {
      if (!($(this).val()) && $(this).parent().is(':visible')) {
        hasMissing = true;
        return false;
      }
    }
  )

  return hasMissing;
}

window.removeSourceItemRequiredErrorMessage = function removeSourceItemRequiredErrorMessage(sender) {
  var target = $(sender)
    .closest("[data-js-fields-group]")
    .find("[id^='invoice_invoice_items_attributes'][id$='_source_item_id']")
    .first()
    .parent();

  if (target.hasClass('field_with_errors')) {
    target.removeClass('field_with_errors');
    target.find('[data-js-invoice-items-error-message]').remove();
  }
}

window.remove_invoice_item_fields = function remove_invoice_item_fields(link) {
  $(link)
    .prev("input[type=hidden]")
    .val("1");
  $(link)
    .closest("[data-js-fields-group]")
    .hide();

  removeSourceItemRequiredErrorMessage(link);

  get_inline_total_inc_tax(link).val("");
  get_inline_unit_price(link).val("");
  get_inline_quantity(link).val("");
  get_inline_discount_percentage(link).val("");
  get_inline_discounted_amount(link).val("");

  updateInvoice();

  document.querySelector('[data-js-add-billable-item]').focus()
}

window.formatMoneyNumber = function formatMoneyNumber(number, precision) {
  return number.toLocaleString(
    'en-AU',
    { minimumFractionDigits: 2, maximumFractionDigits: precision, useGrouping: false }
  )
}
