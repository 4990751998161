window.settingsFacebookBookingsReady = function settingsFacebookBookingsReady() {
  $('.link_red.deactivate_online_bookings').on('click', function (){
    prepareDeactivateOnlineBookingsConfirmationDialog($(this).data('id'));
    $('[data-js-deactivate-online-bookings-confirmation]').dialog('open');
    return false;
  });

  $('[data-js-deactivate-online-bookings-confirmation]').on('click', '[data-js-unlink-facebook-bookings]', function(){
    var bookingsFacebookPageId = $('[data-js-deactivate-online-bookings-confirmation]').data('id');
    var $facebookPageLinkContainer = $("#bookings_facebook_page_" + bookingsFacebookPageId);
    var $deactivationLink = $facebookPageLinkContainer.find('a.deactivate_online_bookings');
    deactivateBookingsUrl = $deactivationLink.attr('href');

    $facebookPageLinkContainer.find('img').removeClass('hidden');
    $deactivationLink.hide();
    $.ajax({
      type: 'DELETE',
      url: deactivateBookingsUrl,
      async: true,
      dataType: 'script'
    });

    $('[data-js-deactivate-online-bookings-confirmation]').dialog('close');
  });
}

window.prepareDeactivateOnlineBookingsConfirmationDialog = function prepareDeactivateOnlineBookingsConfirmationDialog(facebookPageId) {
  $('[data-js-deactivate-online-bookings-confirmation]').data('id', facebookPageId);
  $('[data-js-deactivate-online-bookings-confirmation]').dialog({
    autoOpen: false,
    resizable: false,
    closeOnEscape: true,
    draggable: true,
    dialogClass: "customized-confirm-dialog",
    position: { my: "center", at: "center", of: window },
    modal: true,
    zIndex: 20000,
    title: "Remove Facebook Bookings",
    open: function(event, ui){
      $(this).find('[data-js-unlink-facebook-bookings]').focus();
      $(this).dialog('option', 'position', 'center');
      $('.ui-widget-overlay, .ui-dialog-titlebar-close.ui-corner-all').on('click',function(){
        $('[data-js-deactivate-online-bookings-confirmation]').dialog('close');
      });
      $('[data-js-deactivate-online-bookings-confirmation]').on('click', '[data-js-cancel-unlink-facebook]', function(){
        $('[data-js-deactivate-online-bookings-confirmation]').dialog('close');
      });
    }
  });
}
