window.PatientFormPageReady = function PatientFormPageReady() {
  $('[data-js-patient-save-button]').click(function() {
    $('[data-js-patient-form]').submit();
  });

  $("input#patient_referring_doctor_id").select2({
    minimumInputLength: 1,
    placeholder: "None",
    allowClear: true,
    initSelection: function(element, callback) {
      var data = {id: element.val(), text: element.data('defaultText')};
      callback(data);
    },
    ajax: {
      url: $("input#patient_referring_doctor_id").data('getContacts'),
      dataType: 'json',
      quietMillis: defaultAutocompleteDelay,
      data: function(term, page) {
        return {
          term: term
        };
      },
      results: function(data, page) {
        return {results: data};
      }
    }
  });

  bindPatientSelect($('input.patient_select'));
  $('#patient_first_name').on('change', setRelationshipNameDisplay);
  setRelationshipNameDisplay();

  setupReferralSourceEntity();
}

window.setRelationshipNameDisplay = function setRelationshipNameDisplay() {
  $('span.relationship_patient_first_name').text($('#patient_first_name').val().possessive());
}

window.referrerFormatResult = function referrerFormatResult(referrer) {
  var referrerResultItem = sanitizeHtml(referrer.text);
  var entityType = $('[data-js-referral-source-entity-type]').val();

  if (entityType === 'Patient') {
    referrerResultItem +=
      '<small title="Date of Birth" class="small color-font-light">' + referrer.dob + '</small>';
  }

  return referrerResultItem;
}

window.setupReferralSourceEntity = function setupReferralSourceEntity() {
  $('[data-js-referral-source-entity]').select2({
    minimumInputLength: 1,
    initSelection: function(element, callback) {
      var data = {id: element.val(), text: element.data('defaultText')};
      callback(data);
    },
    formatResult: referrerFormatResult,
    query: function (query) {
      $.ajax({
        url: loadEntityUrl(),
        dataType: 'json',
        quietMillis: defaultAutocompleteDelay,
        data: { term: query.term },
        success: function(data) {
          query.callback({results: data});
        }
      });
    }
  });

  function loadEntityUrl() {
    var entityType = $('[data-js-referral-source-entity-type]').val();
    if(entityType === 'Patient')
      var dataAttribute = 'loadPatientsUrl';
    else if (entityType === 'Contact')
      var dataAttribute = 'loadContactsUrl';

    return $('[data-js-referral-source-entity]').data(dataAttribute);
  }

  $('[data-js-referral-source-type]').on('change', function(){
    var $entityType             = $('[data-js-referral-source-entity-type]');
    var $entitySelectContainer  = $('[data-js-referral-source-entity-select-container]');
    var $entitySelect           = $entitySelectContainer.find('select');
    var $entitySelect2Container = $('[data-js-referral-source-entity-select2-container]');
    var $entitySelect2          = $entitySelect2Container.find('input');
    var $entityHiddenId         = $('[data-js-hidden-referral-source-entity-id]');
    var $extraInfoContainer     = $('[data-js-referral-extra-info-container]');
    var $extraInfo              = $extraInfoContainer.find('input')
    var $selected_item          = $(this).find(':selected');

    $entityHiddenId.prop('disabled', false);
    $entityType.val('');
    hideAndDisableEntitySelect($entitySelectContainer, $entitySelect);
    hideAndDisableEntitySelect2($entitySelect2Container, $entitySelect2);

    if (!$selected_item.val()) {
      $extraInfoContainer.hide();
      $extraInfo.val('');
    } else {
      $extraInfoContainer.show();
      var presetType = $selected_item.data('presetType');

      if(presetType === 'Contact' || presetType === 'Patient') {
        $entityType.val(presetType);
        $entityHiddenId.prop('disabled', true);
        $entitySelect2.prop('disabled', false);
        $entitySelect2Container.show();
      } else if (presetType === 'ReferralSourceTypeSubcategory') {
        $entityType.val(presetType);
        $entityHiddenId.prop('disabled', true);
        $entitySelect.prop('disabled', false);
        reloadAndShowReferralSourceSubcategories($entitySelect, $selected_item);
      }
    }
  });

  function reloadAndShowReferralSourceSubcategories($entitySelect, $selected_item) {
    $.ajax({
      url: $entitySelect.data('loadSubcategoriesUrl'),
      dataType: 'script',
      data: { referral_source_type_id: $selected_item.val() }
    });
  }

  function hideAndDisableEntitySelect($entitySelectContainer, $entitySelect) {
    $entitySelectContainer.hide();
    $entitySelect.prop('disabled', true);
    $entitySelect.val('');
  }

  function hideAndDisableEntitySelect2($entitySelect2Container, $entitySelect2) {
    $entitySelect2Container.hide();
    $entitySelect2.prop('disabled', true);
    $entitySelect2.select2('val', '');
  }
}
