window.PatientAppointmentsPageReady = ->
  $('[data-js-view-appointment-log]').on 'click', ->
    $this = $(this)
    $row = $this.closest('[data-js-appointment]')
    $cells = $row.children()
    $logRow = $row.next '[data-js-appointment-log-container]'
    $log = $logRow.find '[data-js-appointment-log]'
    $logLoader = $logRow.find '[data-js-appointment-log-loader]'

    if $logRow.is ':visible'
      if $row.parent().find('tr:nth-last-child(2)').is($row)
        $row.addClass 'r-table__row--last'
      $log.html ''
      $this.text 'View\xa0log'
      $logRow.hide()
      $cells.removeClass 'border-b0'
      $logLoader.hide()

    else
      $this.prop('disabled', true)
      $logLoader.show()
      $logRow.show()
      $cells.addClass 'border-b0'

      $.ajax
        url: $(this).data().url
        async: true
        type: 'GET'
        success: (response) ->
          $this.text('Hide\xa0log').removeAttr 'disabled'
          $row.removeClass 'r-table__row--last'
          $log.html response
          $logLoader.hide()
          $log.show()
