NodeList.prototype.__proto__ = Array.prototype;

window.defaultAutocompleteDelay = 300

window.isPastBreakpoint = function isPastBreakpoint(breakpoint) {
  var breakpoints = ['default', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'xxxlarge', 'xxxxlarge'];
  var currentBreakpoint = window
    .getComputedStyle(document.body, ':after')
    .getPropertyValue('content')
    .replace(/"/g, '');
  var currentBreakpointIndex = breakpoints.indexOf(currentBreakpoint);
  var requestedBreakpointIndex = breakpoints.indexOf(breakpoint);
  if (requestedBreakpointIndex === -1) return false;
  return currentBreakpointIndex >= requestedBreakpointIndex;
}

window.addTextAreaCallback = function addTextAreaCallback(textArea, callback, delay) {
    var timer = null;
    textArea.onkeyup = function() {
        if (timer) {
            window.clearTimeout(timer);
        }
        timer = window.setTimeout( function() {
            timer = null;
            callback();
        }, delay );
    };
    textArea = null;
}

window.remove_fields = function remove_fields(link, focusElementSelector) {
  $(link).prev("input[type=hidden]").val("1");
  $(link).closest(".fields_group").hide();
  $(link).closest('[data-js-fields-group]').hide();
  if (focusElementSelector) {
    const focusElement = document.querySelector(focusElementSelector)
    if (focusElement) focusElement.focus()
  }
}

window.add_fields = function add_fields(link, association, content) {
  var new_id = new Date().getTime();
  var regexp = new RegExp("new_" + association, "g");
  if(association == "invoice_items") {
    $(link).closest("[data-js-fields-group]").children("[data-js-invoice-items]").append(content.replace(regexp, new_id));
    prepareDiscountFields();
  } else if(association == "purchase_items") {
    $(link).closest("[data-js-group-container]").children("[data-js-purchase-items-widget]").append(content.replace(regexp, new_id));
    setTimeout(function () {
      $('#expense_purchase_items_attributes_' + new_id + '_product_id').select2('open');
    }, 1);
  } else {
    $(link).closest(".fields_group_container").children(".new_fields_placeholder").first().before(content.replace(regexp, new_id));
    $(link).closest("[data-js-group-container]").children("[data-js-new-fields-placeholder]").first().before(content.replace(regexp, new_id));
  }

  if(association == "patient_phone_numbers")
    document.getElementById("patient_patient_phone_numbers_attributes_"+new_id+"_number").focus();
  else if(association =="contact_phone_numbers")
    document.getElementById("contact_contact_phone_numbers_attributes_"+new_id+"_number").focus();
  else if (association == "relationships") {
    var $select = $('#patient_relationships_attributes_' + new_id + '_related_patient_id');
    bindPatientSelect($select);
    setTimeout(function () {
      $select.select2('open');
    }, 1);
    setRelationshipNameDisplay();
  } else if (association == "questions") {
    $('[data-js-template-question-type]').last().trigger('change');
  }
}

window.add_practitioner_fields = function add_practitioner_fields(link, association, content) {
  var new_id = new Date().getTime();
  var regexp = new RegExp("new_" + association, "g");
  $("[data-js-practitioner-form]").append(content.replace(regexp, new_id));
}

/* Payments/Edit */
window.update_payment_amount = function update_payment_amount()
{
  var validNumber = true;
  var total_amount = $("[data-js-payment-amount]");
  var total = 0;

  $("[id^='payment_payment_sources_attributes_'][id$='_amount']").each(function(){
    var amount = $(this).val();
    if(isNaN(amount))
      validNumber = false;
    else
    {
      if(validNumber)
        total += Number(amount);
    }
  });

  if(validNumber)
    total_amount.text(total.toFixed(2));
  else
    total_amount.text("Error");

  total_amount.trigger("change");
  invoice_item_changed_animation(total_amount);
}

window.update_credit_applied_amount = function update_credit_applied_amount()
{
  $('[data-js-payment-outstanding-credit-applied]').text(GetCreditAmountApplied().toFixed(2));
}

window.update_amount_applied_amount = function update_amount_applied_amount()
{
  $('[data-js-payment-outstanding-amount-applied]').text(GetAmountApplied().toFixed(2));
}

window.update_unallocated_payment_amount = function update_unallocated_payment_amount()
{
  amt = Number($("[data-js-payment-amount]").text()) - Number($('[data-js-payment-outstanding-amount-applied]').text());
  if(amt != 0)
  {
    $('[data-js-unallocated-payment-amount]').text(amt.toFixed(2));
    $('[data-js-unallocated-payment-container]').show();
  }
  else
  {
    $('[data-js-unallocated-payment-amount]').text(0);
    $('[data-js-unallocated-payment-container]').hide();
  }
}

window.update_remaining_amount = function update_remaining_amount(source)
{
  var payment_amount = $(source).closest('[data-js-payment-allocation-row]').find("[id^='payment_payment_allocations_attributes_'][id$='_amount']").val();
  payment_amount = isNaN(payment_amount) ? 0 : Number(payment_amount);
  var credit_amount = $(source).closest('[data-js-payment-allocation-row]').find("[id^='payment_payment_allocations_attributes_'][id$='_credit']").val();
  credit_amount = isNaN(credit_amount) ? 0 : Number(credit_amount);
  var inv_outstanding = Number($(source).closest('[data-js-payment-allocation-row]').find("#invoice_ob").val());
  $(source).closest('[data-js-payment-allocation-row]').find("[data-js-payment-allocation-row-remaining]").text((inv_outstanding - payment_amount - credit_amount).toFixed(2));
}


window.update_payment_outstanding_amount = function update_payment_outstanding_amount()
{
  var amount_applied = $("[data-js-payment-outstanding-amount-applied]").text().replace(',', '.');
  var invoice_balance = $("[data-js-invoice-total-outstanding]").text().replace(',', '.');
  var payment_outstanding = $("[data-js-payment-outstanding]");
  var credit_amount_applied = GetCreditAmountApplied();

  if(isNaN(amount_applied) || isNaN(invoice_balance))
    payment_outstanding.text('Error');
  else
  {
    var po = (invoice_balance - amount_applied - credit_amount_applied);
    payment_outstanding.text(po.toFixed(2));
  }

  payment_outstanding.trigger("change");
/*  invoice_item_changed_animation(payment_outstanding); */
}

window.update_payment_allocations_payment_amounts = function update_payment_allocations_payment_amounts()
{
  var payment_amount = isNaN($("[data-js-payment-amount]").text()) ? 0 : Number($("[data-js-payment-amount]").text());

  $("[id^='payment_payment_allocations_attributes_'][id$='_amount']").each(function(){
    var inv_outstanding = Number($(this).closest('[data-js-payment-allocation-row]').find("#invoice_ob").val());
    if(payment_amount > 0 && inv_outstanding > 0) {
      var apply_amount = 0;
      apply_amount = payment_amount > inv_outstanding ? inv_outstanding : payment_amount;
      $(this).val(apply_amount == 0 ? "" : apply_amount.toFixed(2));
      payment_amount -= apply_amount;
    } else if (payment_amount < 0 && inv_outstanding < 0) {
        var apply_amount = 0;
        apply_amount = payment_amount < inv_outstanding ? inv_outstanding : payment_amount;
        $(this).val(apply_amount == 0 ? "" : apply_amount.toFixed(2));
        payment_amount -= apply_amount;
    }
    else {
      $(this).val("");
    }
    update_remaining_amount(this);
  });
  update_amount_applied_amount();
}

window.update_payment_allocations_credit_amounts = function update_payment_allocations_credit_amounts()
{
  var account_credit_amount = Number($("#hid_patient_account_credit").val());

  $("[id^='payment_payment_allocations_attributes_'][id$='credit']").each(function(){
    if(account_credit_amount > 0) {
      var apply_amount = 0;
      var inv_outstanding = Number($(this).closest('[data-js-payment-allocation-row]').find("#invoice_ob").val());
      var payment_amount = $(this).closest('[data-js-payment-allocation-row]').find("[id^='payment_payment_allocations_attributes_'][id$='_amount']").val();
      payment_amount = isNaN(payment_amount) ? 0 : Number(payment_amount);
      var remaining_amount = inv_outstanding - payment_amount;

      apply_amount = account_credit_amount > remaining_amount ? remaining_amount : account_credit_amount;
      $(this).val(apply_amount <= 0 ? "" : apply_amount.toFixed(2));
      account_credit_amount -= apply_amount;
    }
    else {
      $(this).val("");
    }
    update_remaining_amount(this);
  });
  update_credit_applied_amount();
}

window.GetAmountApplied = function GetAmountApplied()
{
  var aa = 0;
  $("[id^='payment_payment_allocations_attributes_'][id$='_amount']").each(function(){
    if(!isNaN(parseFloat(this.value))) {
      aa += parseFloat(this.value);
    }
  });
  return aa;
}

window.GetCreditAmountApplied = function GetCreditAmountApplied()
{
  var cma = 0;
  $("[id^='payment_payment_allocations_attributes_'][id$='_credit']").each(function(){
    if(!isNaN(parseFloat(this.value))) {
      cma += parseFloat(this.value);
    }
  });
  return cma;
}

window.invoice_item_changed_animation = function invoice_item_changed_animation(sender)
{
  standard_animation(sender);
}

window.standard_animation = function standard_animation(sender)
{
  sender.filter(':not(:animated)').addClass("bounce");
  setTimeout(function(){
    sender.removeClass('bounce');
  }, 1400);
}

window.bindPatientSelect = function bindPatientSelect($element) {
  $element.select2({
    minimumInputLength: 1,
    placeholder: "Choose an existing patient",
    initSelection: function(element, callback) {
      var data = { id: element.val(), text: element.data('defaultText') };
      callback(data);
    },
    formatResult: formatSelect2,
    nextSearchTerm: displayCurrentValue,
    ajax: {
      url: $element.data('getPatients'),
      dataType: 'json',
      quietMillis: defaultAutocompleteDelay,
      data: function(term, page) {
        return { term: term };
      },
      results: function(data, page) {
        return { results: data };
      }
    }
  });
}

window.formatSelect2 = function formatSelect2(patient) {
  return sanitizeHtml(patient.text) + ' <span class="patient-select-dob">' + sanitizeHtml(patient.dob) + '</span>';
}

window.displayCurrentValue = function displayCurrentValue(selectedObject, currentSearchTerm) {
  return currentSearchTerm;
}
