import Decimal from 'decimal.js-light'
import { thru } from 'modules/util/functions'
import * as R from 'ramda'
import { toFixed } from './numbers'

export const asCurrency = ({ delimiter, format, precision, separator, subunitsPerUnit, unit }) => {
  const delimit = R.pipe(R.reverse, R.splitEvery(3), R.reverse, R.map(R.reverse), R.join(delimiter))

  return (amount, { amountIsCents = true, noCentsIfWhole = false } = {}) => {
    const amountInDecimal = amountIsCents ? amount / subunitsPerUnit : amount
    const number = thru(amountInDecimal, Number, Math.abs, toFixed(precision))
    const whole = thru(number, R.takeWhile(R.test(/\d/)), delimit)
    const cents = R.takeLast(precision, number)
    const sign = amount < 0 ? '-' : ''

    const excludeCents = precision === 0 || (noCentsIfWhole && Number(cents) === 0)
    const formattedNumber = excludeCents ? whole : [whole, cents].join(separator)
    const formattedCurrency = thru(format, R.replace(/%u/, unit), R.replace(/%n/, formattedNumber))

    return [sign, formattedCurrency].join('')
  }
}

export const toCents = R.curry((subunitsPerUnit, amount) =>
  Decimal(amount).times(subunitsPerUnit).toNumber()
)

export const toDecimal = R.curry((subunitsPerUnit, amount) =>
  Decimal(amount).dividedBy(subunitsPerUnit).toNumber()
)
