window.performanceReportIndexPageReady = ->
  $('[data-js-report-form]').submit (event) ->
    event.preventDefault()
    intervalStart = $(this).find('[data-js-interval-start] input').val()
    intervalEnd = $(this).find('[data-js-interval-end] input').val()
    timeZone = $(this).find('[data-js-time-zone] :selected').val()
    $.ajax
      type: 'POST'
      url: $(this).attr('action');
      data:
        start_date: intervalStart,
        end_date: intervalEnd,
        time_zone: timeZone
    return

window.paymentSummaryReportPageReady = ->
  $('[data-js-report-form]').submit (event) ->
    event.preventDefault()
    intervalStart = $(this).find('[data-js-interval-start] input').val()
    intervalEnd = $(this).find('[data-js-interval-end] input').val()
    timeZone = $(this).find('[data-js-time-zone] :selected').val()
    $.ajax
      type: 'POST'
      url: $(this).attr('action');
      data:
        start_date: intervalStart,
        end_date: intervalEnd,
        time_zone: timeZone
      dataType: 'script'
    return

  getNewReport = ({id}) ->
    if $("#report_#{id}").length
      $.ajax
        type: 'GET'
        url: "/reports/transactions/payment_summary_reports/#{id}"
        async: true
        dataType: 'script'
    return

  privateChannel.bind 'payment_summary_generated', getNewReport
  document.querySelector('[data-js-page-container]').addEventListener 'angular-stopped', ->
    privateChannel.unbind 'payment_summary_generated', getNewReport
