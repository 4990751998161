window.cliniko.directive 'rgButtonLoads', ($rootScope, $timeout) ->
  replace: false
  restrict: 'A'
  scope:
    loading: '=rgButtonLoads'
  template: (element) ->
    "<div ng-transclude ng-class=\"{'visibility-hidden': loading}\"></div>
    <loader-dots ng-class=\"{'hidden': !loading}\"></loader-dots>"
  transclude: true
  link: (scope, element, attrs) ->
    element.addClass 'button-loads'

    shouldSetDisabledAttribute = !element[0].hasAttribute 'ng-disabled'

    scope.$watch 'loading', (value) ->
      if value
        element.addClass 'button-loading'
        element.attr 'disabled', true if shouldSetDisabledAttribute
      else
        element.removeClass 'button-loading'
        element.removeAttr 'disabled' if shouldSetDisabledAttribute

    unless attrs.rgButtonLoads
      # This is a non stateful implementation to replace the default ladda behavior
      element.bind 'click', ->
        $timeout -> scope.loading = true

      $rootScope.$on 'stopRgButtonLoads', ->
        scope.$apply -> scope.loading = false
