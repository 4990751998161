window.accountStatementPageReady = function accountStatementPageReady() {
  $("#business_id").on('change', function(){
    if ($(this).val() == '') {
      $('[data-js-display-business]').removeClass('hidden')
      $('[data-js-hide-summary]').prop('disabled', false)
    } else {
      $('[data-js-display-business]').addClass('hidden')
      $('[data-js-hide-summary]').prop('checked', true)
      $('[data-js-hide-summary]').prop('disabled', true)
    }
  });
}
