import autosize from 'autosize'

window.NoticeBoardPageReady = function NoticeBoardPageReady() {
  addReadMoreButtons($('[data-js-show-more]'));

  $('form.message-board__new-message__form').parsley();

  $('[data-js-messages]').on('click', '[data-js-cancel-edit-message-form]', function(){
    var $form = $(this).closest('form.message-board__new-message__form');
    var $message = $form.prev();
    $form.remove();
    $message.show();
    return false;
  });

  $('[data-js-messages]').on('click', '[data-js-comment-expand-link]', function(){
    var url = $(this).closest('[data-js-message-board-item-content]').data('url');
    $.ajax({
      type: 'GET',
      url: url,
      dataType: 'script'
    });
    return false;
  });

  $('[data-js-messages]').on('click', '[data-js-add-comment]', function(){
    var $addCommentButton = $(this);
    var $form = $addCommentButton.siblings('[data-js-comment-form]');
    var $textarea = $form.find('textarea');
    $form.removeClass('hidden');
    $addCommentButton.addClass('hidden');
    $textarea.focus();
    autosize($textarea);
  });

  $('[data-js-messages]').on('click', '[data-js-comment-cancel]', function(){
    var $form = $(this).closest('[data-js-comment-form]');
    var $textarea = $form.find('textarea');
    $form.addClass('hidden');
    $form.siblings('[data-js-add-comment]').removeClass('hidden').focus();
    $textarea.val('');
    autosize.destroy($textarea);
  });

  $('[data-js-comment-form]').on('submit', function(){
    var $commentForm = $(this);
    var $textarea = $commentForm.find('textarea');
    $commentForm.siblings('[data-js-add-comment]').removeClass('hidden');
    $commentForm.addClass('hidden');
    autosize.destroy($textarea);
  });

  $('[data-js-messages]').on('keypress', '[data-js-message-comment-content]', function(e){
    if(e.which == 13 && !e.shiftKey) {
      e.preventDefault();
      if($(this).val().length > 0 && $(this).attr('submitinprogress') != 'true') {
        $(this).attr('submitinprogress', true);
        $(this).closest("form").submit();
      }
    }
  });

  $('[data-js-messages]').on('click', '[data-js-show-new-message-form]', function(){
    $('[data-js-show-new-message-form], [data-js-message-board-no-messages]').addClass('hidden');
    $('[data-js-message-form]').removeClass('hidden').find('[data-js-message-subject]').focus();
    resetNewMessageForm();
    return false;
  });

  $('[data-js-messages]').on('click', '[data-js-cancel-new-message-form]', function(){
    $('[data-js-show-new-message-form], [data-js-message-board-no-messages]').removeClass('hidden');
    $('[data-js-message-form]').addClass('hidden');
    resetNewMessageForm();
    return false;
  });
}

window.addReadMoreButtons = function addReadMoreButtons(messages) {
  messages.each(function (index, message) {
    $(message).children().last().append(
      '<span class="color-font-light">…</span>&nbsp;' +
      '<button class="link" data-js-comment-expand-link="true">Read more</button>'
    );
  });
}

window.resetNewMessageForm = function resetNewMessageForm() {
  $('form.message-board__new-message__form').parsley().reset();
  $('[data-js-message-form] input[type=text], [data-js-message-form] textarea').val('');
  autosize.destroy($('[data-js-message-form] textarea'));
  autosize($('[data-js-message-form] textarea'));
  stopRgButtonLoads();
}
