window.SettingsSubscriptionPageReady = function SettingsSubscriptionPageReady() {
  refreshShownAndHidden();

  handlePlanUpdatedPush = function(data) {
    onPlanUpdated(data.currentPlanId, data.planIncludesGst, data.planMaxPractitioners, data.planName, data.planPriceWithGst, data.planType);
  }
  privateChannel.bind('plan_updated', handlePlanUpdatedPush);
  document.querySelector('[data-js-page-container]').addEventListener('angular-stopped', function() {
    privateChannel.unbind('plan_updated', handlePlanUpdatedPush);
  });

  setCurrentCheckedCycleButton()

  if($('input#current_plan_num_months').val() == "1") {
    showMonthlyPlans();
  } else {
    showYearlyPlans();
  }

  $('input[name="show_plans_type"]').on('change', function() {
    if ($(this).is(":checked")) {
      switchPlanTable($(this).val());
      setCurrentCycleButton($(this));
    }
  });

  $('[data-js-toggle-exit-survey]').on('click', function() {
    $('[data-js-exit-survey]').show();
    $('[data-js-toggle-exit-survey]').hide();
    $('html, body').animate({ scrollTop: $('[data-js-exit-survey]').position().top - 20 }, 500);
  })

  $("#btn_reactivate_subscription").on('click', function(event){
    if(confirm('This will immediately charge your credit card for the subscription fee. Continue?'))
      return true;
    else
    {
      setTimeout(stopRgButtonLoads);
      event.stopPropagation();
      return false;
    }
  });
}

window.cancelSubscriptionPageReady = function cancelSubscriptionPageReady() {
  $('[data-js-cancel-subscription-reason]').click(function(){
    $('[data-js-cancel-subscription-reason]').removeClass('is-selected');
    $(this).addClass('is-selected');

    var detailedQuestions = $("[data-js-questions-for-reason='" + $(this).data('js-cancellation-reason-key') + "']");

    $('[data-parsley-required=true]').attr('data-parsley-required', false)
    $('[data-js-questions-for-reason]').addClass('hide');
    $('[data-js-cancellation-survey-input]').prop('disabled', true)

    if (detailedQuestions.length) {
      $('[data-js-cancellation-comments]').addClass('hide');
      $(detailedQuestions).removeClass('hide');
      $(detailedQuestions).find('[data-js-answer-required=true]').attr({
        'data-parsley-required': true,
        'data-parsley-required-message': 'Please answer this question'
      });
      $(detailedQuestions).find('[data-js-cancellation-survey-input]').prop('disabled', false)
    } else {
      $('[data-js-cancellation-comments]').removeClass('hide');
    }
  });
}

window.switchPlanTable = function switchPlanTable(plan_type) {
  if(plan_type === "Monthly")
  {
    hideYearlyPlans();
    showMonthlyPlans();
  }
  else
  {
    hideMonthlyPlans();
    showYearlyPlans();
  }

}

window.setCurrentCycleButton = function setCurrentCycleButton(element) {
  $('[data-js-subscription-cycle-buttons] label').removeClass('subscription__cycle-label--current')
  $(element).parent().addClass('subscription__cycle-label--current')
  setCurrentCheckedCycleButton()
}

window.setCurrentCheckedCycleButton = function setCurrentCheckedCycleButton() {
  $('input[name="show_plans_type"]').removeAttr('checked');
  $('.subscription__cycle-label--current > input').first().prop('checked', true);
}

window.onPlanUpdated = function onPlanUpdated(currentPlanId, planIncludesGst, planMaxPractitioners, planName, planPriceWithGst, planType) {
  var $currentPlanRow = $('[data-id="' + currentPlanId + '"]');
  var $previouslySelectedPlanRow = $('[data-js-subscription-plans-list]').find('.subscription__current-plan');
  var $planNameLabel = $('[data-js-current-plan-name]');

  $previouslySelectedPlanRow.find('[data-js="plan-select-button"]').show();
  $previouslySelectedPlanRow.find('[data-js="current"]').addClass('hidden');
  $previouslySelectedPlanRow.removeClass('subscription__current-plan');

  $currentPlanRow.addClass('subscription__current-plan');
  switchPlanTable(planType);
  setCurrentCycleButton($(`input[name="show_plans_type"][value="${planType}"]`));
  $currentPlanRow.find('[data-js="plan-select-button"]').hide();
  $currentPlanRow.find('[data-js="current"]').addClass('flex').removeClass('hidden');

  $planNameLabel.data('planName', planName);
  $planNameLabel.text(planName);
  $('[data-js-current-plan-max-practitioners]').text(planMaxPractitioners);
  var gstString = planIncludesGst ? ' including GST' : ''
  var planPriceString = planPriceWithGst + gstString
  $('[data-js-current-plan-price]').text(planPriceString);

  $('[data-js-processing-plan-request]').addClass('hidden');
}

window.showMonthlyPlans = function showMonthlyPlans() {
  $('.plan-type-monthly').addClass('flex').removeClass('hidden');
}

window.showYearlyPlans = function showYearlyPlans() {
  $('.plan-type-yearly').addClass('flex').removeClass('hidden');
}

window.hideMonthlyPlans = function hideMonthlyPlans() {
  $('.plan-type-monthly').addClass('hidden');
}

window.hideYearlyPlans = function hideYearlyPlans() {
  $('.plan-type-yearly').addClass('hidden');
}

window.refreshShownAndHidden = function refreshShownAndHidden(){
  hideDesiredSections();
  showDesiredSections();
}

window.showDesiredSections = function showDesiredSections() {
  $('[data-js-displayed="true"]').show();
}

window.hideDesiredSections = function hideDesiredSections() {
  $('[data-js-displayed="false"]').hide();
}

window.showProcessingCreditCardInfo = function showProcessingCreditCardInfo() {
  $('[data-js="processing-credit-card-update-request"]').show();
}

window.hideProcessingCreditCardInfo = function hideProcessingCreditCardInfo() {
  $('[data-js="processing-credit-card-update-request"]').hide();
}
