window.StockAdjustmentFormPageReady = function StockAdjustmentFormPageReady() {
  var $decreasingStockSelect = $('[data-js-decreasing-stock]');
  var $increasingStockSelect = $('[data-js-increasing-stock]');
  var $stockLabel = $('[data-js-stock-label]');
  var $stockAdjustmentType = $('#stock_adjustment_adjustment_type');

  $('[name="which_way"]').on('change', function() {
    if ($(this).val() === "up") {
      $decreasingStockSelect.hide();
      $increasingStockSelect.show();
      $stockLabel.text('increasing');
      $stockAdjustmentType.val($increasingStockSelect.val());
    } else {
      $increasingStockSelect.hide();
      $decreasingStockSelect.show();
      $stockLabel.text('decreasing');
      $stockAdjustmentType.val($decreasingStockSelect.val());
    }
  });

  $('select').on('change', function() {
    $stockAdjustmentType.val($(this).val());
  });
}
