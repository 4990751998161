window.resizeSelectToSelectedOption = function resizeSelectToSelectedOption(selectInput) {
  var runResize = function() {
    var $selectedOption = selectInput.find("option:selected");
    var $tester = $('<span class="tester">').text($selectedOption.text());
    $tester.css({
      'font-family': $selectedOption.css('font-family'),
      'font-style': $selectedOption.css('font-style'),
      'font-weight': $selectedOption.css('font-weight'),
      'font-size': $selectedOption.css('font-size')
    });
    $('body').append($tester);
    // The 50px is for select icon
    selectInput.width($tester.width() + 50);
    $tester.remove();
  };

  runResize();
  selectInput.on('change', runResize);
}
