window.settingsDataImportPageReady = function settingsDataImportPageReady() {
  var $importTypeSelect = $('[data-js-import-type]');
  var $importButton = $('[data-js-import-button]');

  $importTypeSelect.on('change', function(e) {
    $importButton.attr('href', '/settings/data_imports/new?data_type=' + e.target.value)
  });

  $('[data-js-page-container]').on('confirm:complete', '[data-js-data-import-delete]', function(e, answer) {
    if (answer) {
      $(this).hide();
      $(this).parent().siblings('[data-js-data-import-status]').html('<span>Deleting</span>');
    }
  });

  function hideDeletedRow(data) {
    if ($('#data_import_' + data.id).length) {
      $('#data_import_' + data.id).hide();
    }
  }

  function buildDataImportTableRow(data) {
    if ($('tr#data_import_' + data.id).length) {
      $.ajax({
        type: 'GET',
        url: '/settings/data_imports/' + data.id + '/data_import_row',
        async: true,
        dataType: 'script'
      });
    }
  }

  privateChannel.bind('import_has_been_deleted', hideDeletedRow);
  privateChannel.bind('import_has_been_finished', buildDataImportTableRow);
  document.querySelector('[data-js-page-container]').addEventListener('angular-stopped', function() {
    privateChannel.unbind('import_has_been_deleted', hideDeletedRow);
    privateChannel.unbind('import_has_been_finished', buildDataImportTableRow);
  });
}
