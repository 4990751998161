window.recallsPageReady = function recallsPageReady() {
  $('[data-js-page-container]').on('click', '[data-js-update-recalled]', function() {
    var $this = $(this);
    var recalled = $this.is(':checked');
    var url = recalled ? $this.data('jsRecalledPath') : $this.data('jsUndoRecalledPath');
    $this.prop('disabled', true);

    $.ajax({
      url: url,
      type: 'POST',
      dataType: 'json',
      success : function(data) {
        var $updatedRow = $('[data-js-recall-id=' +  data.id + ']');
        var recalledText = data.recalled_by ? '<span class="hide print-show-inline color-font">Recalled </span>' + data.recalled_by : '';
        $updatedRow.find('[name="recalled_by"]').html(recalledText);
        $this.prop('disabled', false);
        $this.val(recalled);
      }
    });
  });
}
