import { calculateInvoiceItem as legacyCalculation } from 'modules/invoice-calculator/legacy'
import { calculateInvoiceItem as taxExclusiveCalculation } from 'modules/invoice-calculator/tax-exclusive'
import { calculateInvoiceItem as taxInclusiveCalculation } from 'modules/invoice-calculator/tax-inclusive'
import { CALCULATION_METHODS } from './invoice'

const calculateFields = ({ calculationMethod, ...invoiceItem }) => {
  switch (calculationMethod) {
    case CALCULATION_METHODS.legacy:
      return legacyCalculation(invoiceItem)
    case CALCULATION_METHODS.taxExclusive:
      return taxExclusiveCalculation(invoiceItem)
    case CALCULATION_METHODS.taxInclusive:
      return taxInclusiveCalculation(invoiceItem)
  }
}

const InvoiceItem = { calculateFields }
export default InvoiceItem
