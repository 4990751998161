window.newPerformanceReportPageReady = (reportId) ->

  renderSummary = (attribute, data) ->
    location = $("[#{attribute}]")
    location.find('[data-js-collapsible-section-wrapper]').html(angularCompile(data))
    location.show()

  displayCharts = (charts, chartData, tab) ->
    piecesRequested = 0
    charts.forEach (chartName) ->
      if tab.length > 0
        chartDivName = "#{tab}#{chartName.charAt(0).toUpperCase() + chartName.slice(1)}-js"
      else
        chartDivName = "#{chartName}-js"
      chartDiv = $("##{chartDivName}")
      piecesRequested += 1
      chart = chartData[chartName]
      pointFormat = "{series.name}: {point.y:.0f}"
      showChart = chart?.length && (_.every chart, (seriesData) -> seriesData.data.length)
      if (chartDiv.length > 0) && showChart
        chartDiv.closest('collapsible-section').show()
        new Highcharts.Chart
          chart:
            renderTo: chartDivName
            type: 'spline'
          title:
            text: undefined
          tooltip:
            pointFormat: pointFormat
          yAxis:
            title:
              enabled: false
            min: 0
          xAxis:
            type: 'datetime'
            labels:
              formatter: -> Highcharts.dateFormat('%d-%b-%y', this.value)
          series: chart
    piecesRequested

  piecesRequested = 0
  currentTab = $('input#current_tab').val()
  legacyCharts = [
    'revenueChart',
    'appointmentsChart',
    'averageRevenueChart',
    'didNotArriveChart',
    'newPatientsChart',
    'cancellationsChart',
    'pwuaChart']
  individualCharts = [
    'revenueChart',
    'appointmentsChart',
    'averageRevenueChart',
    'didNotArriveChart',
    'newPatientsChart',
    'cancellationsChart',
    'pwuaChart']
  groupCharts = [
    'revenueChart',
    'appointmentsChart',
    'averageRevenueChart',
    'attendeesChart'
    'didNotArriveChart',
    'newPatientsChart',
    'cancellationsChart',
    'pwuaChart']
  revenueCharts = ['revenueChart',
    'appointmentsChart',
  ]
  summaryParams = {
      'business[id]': $('select#business_id').val(),
      'practitioner[id]': $('select#practitioner_id').val()
  }

  $.getJSON "/reports/practitioners/performance_reports/#{reportId}/summary", summaryParams, (data) ->
      piecesRequested += 1
      if data.legacy
        totalPieces = individualCharts.length + 1
        renderSummary('data-js-performance-summary', data.legacy)
      else
        totalPieces = individualCharts.length + groupCharts.length + revenueCharts.length + 1
        renderSummary('data-js-individual-performance-summary', data.individual)
        renderSummary('data-js-group-performance-summary', data.group)
        renderSummary('data-js-revenue-summary', data.revenue)

      if piecesRequested == totalPieces
        $("[data-js-loading-message]").hide()

  query_params = {
    'business[id]': $('select#business_id').val(),
    'practitioner[id]': $('select#practitioner_id').val()
  }

  $.getJSON "/reports/practitioners/performance_reports/#{reportId}/charts", query_params, (data) ->
    if data.split
      totalPieces = individualCharts.length + groupCharts.length + revenueCharts.length + 1
      piecesRequested += displayCharts(individualCharts, data.individual, 'individual')
      piecesRequested += displayCharts(groupCharts, data.group, 'group')
      piecesRequested += displayCharts(revenueCharts, data.revenue, '')
    else
      totalPieces = legacyCharts.length + 1
      piecesRequested += displayCharts(legacyCharts, data, '')
    if piecesRequested >= totalPieces
      $("[data-js-loading-message]").hide()
