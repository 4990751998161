var LetterStatus = function (selector) {
  var letterState = {
    content: null
  }

  var getValue = function () {
    return $(selector).val()
  }

  var statusChecker = {
    reset: function () {
      letterState.content = getValue()
    },
    hasChanged: function () {
      return getValue() !== letterState.content
    }
  }
  // Ensure angular has loaded
  setTimeout(function () {
    statusChecker.reset()
  }, 0)

  return statusChecker
}

var letterStatus

window.LettersPageReady = function LettersPageReady() {
  initializeSelect2Control();
  letterStatus = LetterStatus('#letter_content');

  $('select#letter_template_select').on('change', function(){
    $.ajax({
      url: $('select#letter_template_select').data('ajaxPath'),
      data: { 'letter_template_id' : $('select#letter_template_select').val() },
      async: true,
      dataType: 'script',
      complete: function () {
        letterStatus.reset()
      }
    });
  })

  $('select#letter_template_select').on('select2-open', function() {
    warnChangesWillBeLost(this);
  });

  bindOnChangeActionForPlaceholders();

  $('#letter_description')
    .on('invalid', function(e) {
      if (e.target.value.trim().length == 0) {
        e.preventDefault()
        e.target.focus()

        this.parentElement.classList.add('field_with_errors')
      }

      setTimeout(stopRgButtonLoads)
    })
    .on('input', function(e) {
      this.parentElement.classList.remove('field_with_errors')
    })
}

window.initializeSelect2Control = function initializeSelect2Control() {
  $("select#letter_template_select").select2({
    minimumResultsForSearch: 10
  });

  $("select#letter_practitioner_id").select2({
    minimumResultsForSearch: 10
  });

  $("select#letter_business_id").select2({
    minimumResultsForSearch: 10
  });

  $("input#letter_contact_id").select2({
    minimumInputLength: 1,
    placeholder: "Search for a contact … ",
    initSelection: function(element, callback) {
      var data = {id: element.val(), text: element.data('defaultText')};
      callback(data);
    },
    ajax: {
      url: $("input#letter_contact_id").data('getContacts'),
      dataType: 'json',
      quietMillis: defaultAutocompleteDelay,
      data: function(term, page) {
        return {
          term: term
        };
      },
      results: function(data, page) {
        return {results: data};
      }
    }
  });
}

window.warnChangesWillBeLost = function warnChangesWillBeLost(sender) {
  if (!letterStatus.hasChanged()) {
    return false;
  }

  if(confirm('You will lose any changes made to the letter. Continue?'))
    letterStatus.reset();
  else
    $(sender).select2("close");
}

window.bindOnChangeActionForPlaceholders = function bindOnChangeActionForPlaceholders() {

  $('.letter_template_placeholder_select').on('select2-open', function() {
    warnChangesWillBeLost(this);
  });

  $('.letter_template_placeholder_select').on('change', function() {

    var letter_template_id = $('select#letter_template_select').val();
    var letter_practitioner_id = $('select#letter_practitioner_id').length ? $('select#letter_practitioner_id').val() : '';
    var letter_contact_id = $('input#letter_contact_id').length ? $('input#letter_contact_id').val() : '';
    var letter_business_id = $('select#letter_business_id').length ? $('select#letter_business_id').val() : '';

    $.ajax({
      url: $(this).data('ajaxPath'),
      data: { 'letter_template_id' : letter_template_id,
              'letter_practitioner_id' : letter_practitioner_id,
              'letter_contact_id' : letter_contact_id,
              'letter_business_id' : letter_business_id },
      async: true,
      dataType: 'script',
      complete: function () {
        letterStatus.reset()
      }
    });
  });
}
