class window.Color
  constructor: (@hexColor) ->
    @colorComponents = @hexColor.match /[0-9A-F]{1,2}/gi

  brightness: ->
    @colorComponents.reduce ((sum, component) -> sum + parseInt(component, 16)), 0

  luminance: (lum) ->
    # validate hex string
    hex = String(@hexColor).replace(/[^0-9a-f]/gi, '')
    if hex.length < 6
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
    lum = lum or 0
    # convert to decimal and change luminosity
    rgb = '#'
    c = undefined
    i = undefined
    i = 0
    while i < 3
      c = parseInt(hex.substr(i * 2, 2), 16)
      c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16)
      rgb += ('00' + c).substr(c.length)
      i++
    rgb
