window.ExpenseFormPageReady = function ExpenseFormPageReady() {
  showSelectForExpenseProducts();

  $("input[type=radio][id^='expense_is_purchase']").on('change', function(){
    $('[data-js-purchase-items]').toggleClass("hidden");
    setPrefixInputPadding();
  });

  $('[data-js-page-container]').on('change', 'input[data-js-expense-total]', function() {
    updateTaxAmount();
  });

  $('[data-js-page-container]').on('change', 'select[data-js-expense-tax-id]', function() {
    updateTaxAmount();
  });

  $("[data-js-expense-vendor-name]").autocomplete({
    source: $("[data-js-expense-vendor-data]").val().split('|')
  });

  $("[data-js-expense-category-name]").autocomplete({
    source: $("[data-js-expense-category-data]").val().split('|')
  });
}

window.updateTaxAmount = function updateTaxAmount() {
  var expense_total = parseFloat($('input[data-js-expense-total]').val());

  if(!isNaN(expense_total)) {
    var tax_rate = 0
    if($('select[data-js-expense-tax-id]').val().length > 0) {
      var tax_rate_selector = 'input#hidden_tax_' + $('select[data-js-expense-tax-id]').val();
      tax_rate = parseFloat($(tax_rate_selector).val());
    }

    var tax_amount = getTaxComponent(tax_rate, expense_total);
    $('input[data-js-expense-tax-total]').val(tax_amount.toFixed(2));
  } else {
    $('input[data-js-expense-tax-total]').val('');
  }
}

window.getTaxComponent = function getTaxComponent(tax_rate, amount) {
  return (amount - amount/(1+tax_rate/100));
}

window.showSelectForExpenseProducts = function showSelectForExpenseProducts(){
   // Select2 for remote load control
   $("input.needs_cool_select").each(function(){
    if(isNaN($(this).select2('val'))) {
      $(this).select2({
        minimumInputLength: 1,
        placeholder: "Search for a product … ",
        initSelection: function(element, callback) {
          var data = {id: element.val(), text: element.data('defaultText')};
          callback(data);
        },
        ajax: {
          url: $("[source_item_type$='Product']").data('getProducts'),
          dataType: 'json',
          quietMillis: defaultAutocompleteDelay,
          data: function(term, page) {
            return {
              term: term
            };
          },
          results: function(data, page) {
            return {results: data};
          }
        }
      });
    }
  });

  // Select2 for preloaded control
  $("select.needs_cool_select").select2({
    placeholder: "Select a product … "
  });
}

window.remove_purchase_item_fields = function remove_purchase_item_fields(link) {
  $(link).prev("input[type=hidden]").val("1");
  $(link).closest("[data-js-fields-group]").hide();
  document.querySelector('[data-js-add-to-expense]').focus()
}
