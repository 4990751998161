import * as _ from 'modules/util'
import * as R from 'ramda'
import { validateDiscount } from './util'

export const calculateInvoiceItem = ({
  unitPrice,
  quantity,
  taxRate,
  discountType,
  discountValue,
}) => {
  const numericValues = [discountValue, quantity, taxRate, unitPrice]
  _.throwUnless(numericValues.every(_.isNumber), 'One or more values are not numeric.', {
    cause: { values: numericValues },
  })

  const extendedPrice = _.convertNegativeZeroToZero(unitPrice * quantity)

  const discountPercentage =
    discountType === 'fixed'
      ? _.thru(
          discountValue / extendedPrice,
          R.multiply(100),
          _.round(10),
          _.convertNegativeZeroToZero
        )
      : discountValue

  const netPrice =
    discountType === 'fixed'
      ? _.round(0, extendedPrice) - _.round(0, discountValue)
      : _.thru(extendedPrice, R.multiply((100 - discountPercentage) / 100), _.round(0))

  const discountedAmount = _.round(0, extendedPrice) - netPrice
  validateDiscount({ amount: discountedAmount, max: _.round(0, extendedPrice) })

  const taxAmount = _.thru(netPrice * (taxRate / 100), _.round(0), _.convertNegativeZeroToZero)

  const totalIncTax = netPrice + taxAmount

  return {
    unitPrice,
    quantity,
    taxRate,
    discountType,
    discountedAmount,
    discountPercentage,
    netPrice,
    taxAmount,
    totalIncTax,
  }
}
