if (window.Highcharts) {
  window.Highcharts.SVGRenderer.prototype.setSize = function setSize (width, height, animate) {
    const renderer = this
    const alignedObjects = renderer.alignedObjects
    let i = alignedObjects.length

    renderer.width = width
    renderer.height = height

    renderer.boxWrapper[window.Highcharts.pick(animate, true) ? 'animate' : 'attr']({
      viewBox: '0 0 ' + width + ' ' + height
    })

    while (i--) {
      alignedObjects[i].align()
    }
  }
}
