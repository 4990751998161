import Cookies from 'cookies-js'

export default class CookieToggle
  constructor: (@key, options={}) ->
    # Migrate from previous local storage toggle to cookie
    try
      if storedValue = localStorage.getItem @key
        @set storedValue == "true"
        localStorage.removeItem @key

    if cookieValue = Cookies.get @key
      @value = cookieValue == "true"
    else
      @set(options.default || false)

  toggle: ->
    @set !@value

  set: (value) ->
    @value = !!value
    Cookies.set @key, @value, expires: Infinity
    @value
