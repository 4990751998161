window.settingsDataExportPageReady = ->
  $("[data-js-export-type]").change (event) ->
    selectedOption = $('[data-js-export-type] option').filter(':selected')
    checkbox = $('[data-js-include-deleted]')
    formatSelect = $('[data-js-file-format]')
    deletedCallout = $('[data-js-deleted-warning]')
    if selectedOption.attr('data-js-deleted-record-support') == 'false'
      checkbox.attr('disabled', true)
      checkbox.removeAttr('checked')
      deletedCallout.removeClass('visually-hidden')
      deletedCallout.removeAttr('aria-hidden')
    else
      checkbox.removeAttr('disabled')
      deletedCallout.addClass('visually-hidden')
      deletedCallout.attr('aria-hidden', 'true')

    if selectedOption.attr('data-js-file-export') == 'true'
      formatSelect.attr('disabled', true)
    else
      formatSelect.removeAttr('disabled')

    if selectedOption.attr('data-js-patient-form-template') == 'true'
      $('fieldset[data-js-patient-form-template]').removeClass('hidden')
    else
      $('fieldset[data-js-patient-form-template]').addClass('hidden')

    if selectedOption.attr('data-js-treatment-note-template') == 'true'
      $('fieldset[data-js-treatment-note-template]').removeClass('hidden')
    else
      $('fieldset[data-js-treatment-note-template]').addClass('hidden')

  $("[data-js-export-form]").submit (event) ->
    event.preventDefault()
    selectedOption = $(this).find('[data-js-export-type] option').filter(':selected')
    url = selectedOption.val()
    if selectedOption.attr('data-js-patient-form-template') == 'true'
      title = $('fieldset[data-js-patient-form-template] option').filter(':selected').val()
    else if selectedOption.attr('data-js-treatment-note-template') == 'true'
      title = $('fieldset[data-js-treatment-note-template] option').filter(':selected').val()
    else
      title = selectedOption.data('title')
    intervalStart = $(this).find('[data-js-interval-start] input').val()
    intervalEnd = $(this).find('[data-js-interval-end] input').val()
    includeDeletedCheckbox = $('[data-js-include-deleted]')
    sendEmailCheckbox = $('[data-js-send-email-when-done]')
    includeDeleted = !includeDeletedCheckbox.attr('disabled') && includeDeletedCheckbox.prop('checked')
    sendEmail = !sendEmailCheckbox.attr('disabled') && sendEmailCheckbox.prop('checked')
    selectedFormat = $(this).find('[data-js-file-format] option').filter(':selected')
    fileFormat = selectedFormat.val()
    $.ajax
      type: 'post'
      url: url
      data:
        interval_start: intervalStart
        interval_end: intervalEnd
        title: title
        include_deleted: includeDeleted
        file_format: fileFormat
        send_email_when_done: sendEmail
      dataType: 'script'
    return

  privateChannel.bind 'export_has_been_generated', buildDataExportTableRow
  document.querySelector('[data-js-page-container]').addEventListener 'angular-stopped', ->
    privateChannel.unbind 'export_has_been_generated', buildDataExportTableRow

  currentDate = new Date()
  year = currentDate.getFullYear()
  month = currentDate.getMonth()
  startDate = new Date(year, month - 3, 1)
  endDate = new Date(year, month, 0)

  $('[data-js-interval-start] input').pickadate('picker').set('select', startDate)
  $('[data-js-interval-end] input').pickadate('picker').set('select', endDate)

window.buildDataExportTableRow = (data) ->
  if $("#data_export_#{data.id}").length
    $.ajax
      type: 'GET'
      url: "/settings/data_exports/#{data.id}/data_export_row"
      async: true
      dataType: 'script'
  return
