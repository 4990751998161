window.ContactFormPageReady = function ContactFormPageReady() {
  var $doctor_radio = $('#contact_contact_type_1');

  $("[id^='contact_contact_type_']").on('change', function(){
    if($doctor_radio.is(':checked')) {
      $('[data-js-doctor-information-container]').show();
    }
    else {
      $("[data-js-doctor-information-container]").hide();
    }
  });
}
