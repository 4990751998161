import * as _ from 'modules/util'
import * as R from 'ramda'

export const calculateInvoiceItem = ({
  unitPrice,
  quantity,
  taxRate,
  discountType,
  discountValue,
}) => {
  const numericValues = [discountValue, quantity, taxRate, unitPrice]
  _.throwUnless(numericValues.every(_.isNumber), 'One or more values are not numeric.', {
    cause: { values: numericValues },
  })

  const extendedPrice = _.convertNegativeZeroToZero(unitPrice * quantity)

  const taxAmountBeforeDiscount = _.thru(
    unitPrice * (taxRate / 100),
    _.round(3),
    R.multiply(quantity),
    _.convertNegativeZeroToZero
  )

  const discountPercentage = calculateItemDiscountPercentage({
    type: discountType,
    value: discountValue,
    total: extendedPrice + taxAmountBeforeDiscount,
  })

  if (discountPercentage < 0 || discountPercentage > 100) {
    throw new Error('Discount is not valid.', { cause: { value: discountPercentage } })
  }

  const discountedAmount = calculatePercent({
    value: extendedPrice + taxAmountBeforeDiscount,
    percentage: discountPercentage,
  })

  const discountAmount = calculatePercent({ value: extendedPrice, percentage: discountPercentage })

  const netPrice = _.round(3, extendedPrice - discountAmount)

  const taxDiscountAmount = calculatePercent({
    value: taxAmountBeforeDiscount,
    percentage: discountPercentage,
  })

  const taxAmount = taxAmountBeforeDiscount - taxDiscountAmount

  const totalIncTax = netPrice + taxAmount

  return {
    unitPrice,
    quantity,
    taxRate,
    discountType,
    discountedAmount,
    discountPercentage,
    netPrice,
    taxAmount,
    totalIncTax,
  }
}

const calculateItemDiscountPercentage = ({ type, value, total }) => {
  if (type === 'percentage') return value

  if (total === 0) {
    return total
  } else {
    return _.convertNegativeZeroToZero(_.round(10, (value / total) * 100))
  }
}

const calculatePercent = ({ value, percentage }) =>
  _.convertNegativeZeroToZero(value * (percentage / 100))
