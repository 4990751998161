import * as _ from 'modules/util'
import * as R from 'ramda'

export const CALCULATION_METHODS = Object.freeze({
  legacy: 1,
  taxExclusive: 2,
  taxInclusive: 3,
})

const calculateFields = ({ calculationMethod, invoiceItems }) => {
  const sumPerProperty = property => _.thru(invoiceItems, R.map(R.prop(property)), R.sum)

  const discountedAmount = sumPerProperty('discountedAmount')
  const taxAmount = sumPerProperty('taxAmount')
  const totalAmount = sumPerProperty('netPrice')

  if (calculationMethod === CALCULATION_METHODS.legacy) {
    return {
      discountedAmount,
      taxAmount,
      totalAmount: _.round(3, totalAmount),
      netAmount: _.round(2, totalAmount + taxAmount),
    }
  } else {
    return {
      discountedAmount,
      taxAmount,
      totalAmount,
      netAmount: sumPerProperty('totalIncTax'),
    }
  }
}

const Invoice = { calculateFields, CALCULATION_METHODS }

export default Invoice
