window.PaymentFormPageReady = function PaymentFormPageReady(opts) {
  $('[data-js="payment-patient-id"]').select2({
    minimumInputLength: 1,
    initSelection: function(element, callback) {
      var data = {id: element.val(), text: element.data('defaultText')};
      callback(data);
    },
    ajax: {
      url: $('[data-js="payment-patient-id"]').data('getPatients'),
      dataType: 'json',
      quietMillis: defaultAutocompleteDelay,
      data: function(term, page) {
        return {
          term: term
        };
      },
      results: function(data, page) {
        return {results: data};
      }
    }
  });

  $('[data-js="payment-patient-id"]').on('change', function(){
    $.ajax({
      url: $(this).data('path'),
      data: {
        'patient_id': $(this).val(),
        'business_id': $('#payment_business_id').val()
      },
      async: true,
      dataType: 'script'
    });
  });

  if (isPastBreakpoint('large')) {
    var patientInput = $('[data-js="payment-patient-id"]')
    if (patientInput.val() === '' && !patientInput.hasClass('field_with_errors')) {
      setTimeout(function () { $('[data-js="payment-patient-id"]').select2('open') });
    } else {
      setTimeout(function () { $('[data-js="payment-source-field"]:first').focus() }, 50);
    }
  }
      /* payment source amount changed */
  $("[id^='payment_payment_sources_attributes_'][id$='_amount']").on('change', function(){
    update_payment_amount();
  });

  /* payment allocation amount changed */
  $("[id^='payment_payment_allocations_attributes_'][id$='_amount']").on('change', function(){
    update_payment_allocations_credit_amounts();
    update_remaining_amount(this);
    update_amount_applied_amount();
    update_payment_outstanding_amount();
    update_unallocated_payment_amount();
  });

  /* payment allocation credit amount changed */
  $("[id^='payment_payment_allocations_attributes_'][id$='_credit']").on('change', function(){
    update_remaining_amount(this);
    update_credit_applied_amount();
    update_payment_outstanding_amount();
  });

  /* payment total changed */
  $("[data-js-payment-amount]").on('change', function(){
    update_payment_allocations_payment_amounts();
    update_payment_allocations_credit_amounts();
    update_payment_outstanding_amount();
    update_unallocated_payment_amount();
  });

  /* payment applied changed */
  $("[data-js-payment-outstanding-amount-applied]").on('change', function(){
    update_unallocated_payment_amount();
  });

  if (opts && opts.autoAllocate) {
    update_payment_amount();
  }
}
