window.twoFactorAuthReady = function twoFactorAuthReady() {
  $('[data-js-page-container]').on('click', '[data-js-print-backup-codes]', function(){
    open_window = window.open();
    open_window.document.write($('[data-js-otp-backup-codes-list]').html());
    open_window.print();
    open_window.close();
  });
}

window.SettingsUsersIndexReady = function SettingsUsersIndexReady() {
  var rowVisibility = function() {
    $('[data-js-user-table-row]').removeClass('r-table__row--last');
    $('[data-js-user-table-row]:visible').last().addClass('r-table__row--last');
  };
  rowVisibility();
  var $inactiveRow = $('[data-js-include-inactive]');
  $inactiveRow.on('change', function() {
    if ($inactiveRow.is(':checked')) {
      $('[data-js-inactive-user]').show();
      rowVisibility();
    } else {
      $('[data-js-inactive-user]').hide().removeClass('r-table__row--last');
      rowVisibility();
    }
  });
}

window.PractitionerAvailableDayFieldsReady = function PractitionerAvailableDayFieldsReady()
{
  $('[data-js-prac-active-radio]').on('change', function(){
    if ($('[data-js-prac-active-radio-false]').is(':checked')) {
      $('[data-js-practitioner-form]').hide();
    }
    else {
      $('[data-js-practitioner-form]').show();
    }
  });

  $('[data-js-user-active-radio]').on('change', function(){
    if ($('[data-js-user-active-radio-false]').is(':checked')) {
      $('[data-js-prac-active-radio-true]').prop('checked', false);
      $('[data-js-prac-active-radio-false]').prop('checked', true);
      $('[data-js-prac-active-radio').prop('disabled', true);
      $('[data-js-user-active-api-keys-warning]').show();
      $('[data-js-prac-active-radio').change();
    }
    else if ($('[data-js-user-was-a-practitioner]').val() == 'true') {
      $('[data-js-prac-active-radio-true]').prop('checked', true);
      $('[data-js-prac-active-radio-false]').prop('checked', false);
      $('[data-js-prac-active-radio').prop('disabled', false);
      $('[data-js-prac-active-radio').change();
    }
    else {
      $('[data-js-user-active-api-keys-warning]').hide();
      $('[data-js-prac-active-radio').prop('disabled', false);
    }
  });

  var errors = $('.field_with_errors .message');

  function setErrorHeight (_, error) {
    var height = error.clientHeight + 30;
    var errorContainer = error.closest('[data-js-fields-group]') || error.closest('[data-js-group-container]');
    $(errorContainer).css({paddingTop: height});
  }

  function handleErrorLayout() { errors.each(setErrorHeight); }

  if (errors.length) {
    handleErrorLayout();
    window.addEventListener('resize', _.throttle(handleErrorLayout, 200));
  }
}
