window.Highcharts && window.Highcharts.setOptions({
  credits: {
    enabled: false
  },
  chart: {
    borderWidth: 0,
    spacingLeft: 0,
    spacingRight: 0,
    spacingTop: 10,
    spacingBottom: 0,
    style: {
      fontFamily: 'source-sans-pro, helvetica, arial, sans-serif'
    }
  },
  tooltip: {
    backgroundColor: '#ffffff',
    borderRadius: 6,
    borderWidth: 1,
    // No border or colored border causes rendering bug in safari
    borderColor: 'rgba(0,0,0,0)',
    shadow: false,
    style: {
      fontSize: '14px',
      padding: '10px'
    }
  },
  plotOptions: {
    areaspline: {
      lineWidth: 2,
    },
    spline: {
      lineWidth: 2,
    },
    pie: {
      dataLabels: {
        crop: false,
        style: {
          fontSize: '16px'
        }
      }
    }
  },
  legend: {
    enabled: true,
    itemMarginTop: 3,
    itemMarginBottom: 3,
    itemStyle: {
      fontSize: '1rem',
      lineHeight: 22
    }
  },
  xAxis: {
    labels: {
      style: {
        fontSize: '14px'
      }
    },
    lineColor: '#ccd6eb',
    showFirstLabel: false,
    tickColor: '#ccd6eb'
  },
  yAxis: {
    labels: {
      style: {
        fontSize: '14px'
      }
    }
  },
  lang: {
    noData: "There's no information for your selections."
  },
  responsive: {
    rules: [{
      condition: {
        minWidth: 625
      },
      chartOptions: {
        xAxis: {
          labels: {
            style: {
              fontSize: '16px'
            }
          }
        },
        yAxis: {
          labels: {
            style: {
              fontSize: '16px'
            }
          }
        }
      }
    }]
  },
  noData: {
    style: {
      fontFamily: 'source-sans-pro, helvetica, arial, sans-serif',
      fontSize: '16px',
      fontWeight: 'normal'
    }
  }
})
