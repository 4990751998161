# IMPORTANT: All icons need to be the innner content of the svg element only
# All svg's should be designed on a 1024 x 1024 artboard to match the viewBox of 0 0 1024 1024
# If the artwork is not square then make sure to center it within the artboard.

import * as icons from './icons/'

window.cliniko.component 'icon',
  bindings:
    classes: '@'
    iconClass: '<?'
    iconName: '@'
    size: '<?'
    styles: '<?'
  template: '''
    <svg
      class="{{ ::$ctrl.iconClass ? 'icon' : '' }} {{ $ctrl.classes }}"
      ng-attr-width="{{::$ctrl.size}}"
      ng-attr-height="{{::$ctrl.size}}"
      ng-bind-html="::$ctrl.data"
      ng-style="$ctrl.styles"
      role="presentation"
      viewBox="0 0 1024 1024"
    ></svg>
  '''
  controller: ($sce, $element, $timeout) ->
    @$onInit = ->
      @size ?= 11
      @iconClass ?= true
      icon = if @iconName == 'appointments'
        "appointments-#{moment().date()}"
      else
        @iconName
      @data = $sce.trustAsHtml icons[icon]
    return
