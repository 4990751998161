window.generalSettingsPageReady = (currentCountryCode, currentCurrencyCode) ->
  USER_TFA_REQUIRED =
    selector: '[data-js-requires-two-factor-warning]'
    errorMessage: 'You must have two factor authentication enabled on your user account in order to require all users to enable two factor authentication.'

  CANNOT_DISABLE_TFA =
    selector: '[data-js-requires-xero-warning]'
    errorMessage: 'The Xero integration requires you keep two factor authentication requirement enabled'

  tfaCheckbox   = document.querySelector('[data-js-requires-two-factor-field]')
  tfaWasChecked = tfaCheckbox.checked
  timeZoneSupportWasChecked = document.querySelector('[data-js-time-zone-support]').checked

  $('[data-js-requires-two-factor-field]').on 'change', (event) ->
    warning = warningToShow()
    if warning
      $(warning.selector).show()
    else
      $(USER_TFA_REQUIRED.selector).hide()
      $(CANNOT_DISABLE_TFA.selector).hide()

  $('[data-js-general-settings-form]').on 'submit', (event) ->
    warning = warningToShow()
    if warning
      event.preventDefault()
      setTimeout(stopRgButtonLoads)
      $('html, body').animate scrollTop: $(warning.selector).position().top, 500
      Flash.error(warning.errorMessage)

  warningToShow = ->
    if tfaCheckbox.checked && tfaCheckbox.dataset.currentUserHasTwoFactorEnabled == "false"
      USER_TFA_REQUIRED
    else if tfaWasChecked && !tfaCheckbox.checked && tfaCheckbox.dataset.accountIsConnectedToXero == "true"
      CANNOT_DISABLE_TFA

  $('[data-js-country-select]').on 'change', ->
    $('[data-js-country-warning]').toggleClass('hidden', this.value == currentCountryCode)

  $('[data-js-currency-select]').on 'change', ->
    $('[data-js-currency-warning]').toggleClass('hidden', this.value == currentCurrencyCode)

  $('[data-js-time-zone-support]').on 'change', ->
    isHidden = this.checked || !timeZoneSupportWasChecked
    $('[data-js-disabling-time-zone-support-warning]').toggleClass('hidden', isHidden)
