window.PatientShowPageReady = function PatientShowPageReady() {
  var $ngApp = $('#cliniko-angular')
  var rgModal = $ngApp.injector().get('rgModal');
  var $mergeModal = $('[data-js-merge-patients-modal]');

  $('[data-js-merge-patients-button]').on('click', function() {
    $mergeModal.show();
    rgModal.open('[data-js-merge-patients-modal]');
    $ngApp.scope().$apply();
    $.ajax({
      url: $('[data-js-get-duplicates-path]').data('jsGetDuplicatesPath'),
      dataType: 'script',
      complete: function () {
        var buttons = document.querySelectorAll('[data-js-table-button]');
        buttons.forEach(function (button) {
          button.style.height = getComputedStyle(parentNodeMatches(button, 'td'))['height'];
        });
      }
    });
  });

  $mergeModal.on('click', '[data-js-cancel-merge-patients]', function(event) {
    rgModal.close('[data-js-merge-patients-modal]');
    $ngApp.scope().$apply();
  });
}
