import { capitalize } from 'lodash'

window.cliniko.factory 'rgFlash', ($timeout) ->
  humanize = (attributeName) ->
    capitalize(attributeName).replace('_', ' ')

  clear: Flash.clear
  dismiss: Flash.clear
  error: (messages='Something went wrong! Please try again.', options) ->
    Flash.error messages, options
  success: (messages='', options) ->
    Flash.success messages, options
  warning: (messages='', options) ->
    Flash.warning messages, options
  info: (messages='', options) ->
    Flash.info messages, options
  resourceResponseError: (response, messages = '', options={}) ->
    responseErrors = response?.data?.errors
    if responseErrors
      errors = for attribute, error of responseErrors
        if options.hideAttributeName || attribute == 'base'
          capitalize error
        else
          [humanize(attribute), error].join(' ')
      @error errors, options
    else if response?.data?.message
      @error response.data.message, options
    else if messages
      @error messages, options
    else if response?.config?.method == 'DELETE'
      @error 'Something went wrong while deleting. Please try again.', options
    else
      @error null, options
