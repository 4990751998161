import { useSetMessages } from './messages'

export const useFlashMessage = () => {
  const setMessages = useSetMessages()
  const addMessageWithType = type => (message, options) =>
    setMessages({ ...options, message, type })

  return {
    flashError: addMessageWithType('error'),
    flashInfo: addMessageWithType('info'),
    flashSuccess: addMessageWithType('success'),
    flashWarning: addMessageWithType('warning'),
  }
}
