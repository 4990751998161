import Pusher from 'pusher-js'

window.initializePusher = function initializePusher (apiKey, cluster, accountChannelName) {
  window.privateChannelName = 'private-cliniko-' + accountChannelName

  if (window.environment === 'test') {
    window.pusher = { connection: { socket_id: {} } }
    window.privateChannel = { bind: function () {} }
  } else if (!window.pusher) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    window.pusher = new Pusher(apiKey, {
      channelAuthorization: { headers: { 'X-CSRF-Token': csrfToken } },
      cluster
    })

    window.privateChannel = window.pusher.subscribe(window.privateChannelName)
  }
}
